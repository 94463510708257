import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { StaticImage } from 'gatsby-plugin-image';

import {
    container,
    innerContainer,
    info,
    prizes,
    prizesMobile,
    background,
} from './halloween-prize.module.scss';

const prizesImg = '../../assets/images/halloween/prizes.png';
const prizesMobileImg = '../../assets/images/halloween/prizes-mobile.png';
const prizesBackgroundMobileImg = '../../assets/images/halloween/prizes-background-mobile-2.png';

interface IHalloweenPrizeProps {
    className?: string;
}

const HalloweenPrize: React.FC<IHalloweenPrizeProps> = ({ className = '' }) => {
    const { t } = useI18next();

    return (
        <div className={`${className} ${container}`}>
            <div className={innerContainer}>
                <StaticImage src={prizesImg} alt={''} className={prizes} />
                <StaticImage src={prizesMobileImg} alt={''} className={prizesMobile} />
                <p className={info}>
                    {t('halloween.prize.info.1')}
                    <strong>{t('halloween.prize.info.2')}</strong>
                    {t('halloween.prize.info.3')}
                    <strong>{t('halloween.prize.info.4')}</strong>
                    {t('halloween.prize.info.5')}
                    <br />
                    <strong>{t('halloween.prize.info.6')}</strong>
                </p>
                <StaticImage src={prizesBackgroundMobileImg} alt={''} className={background} />
            </div>
        </div>
    );
};

export default HalloweenPrize;
