import React from 'react';
import { graphql } from 'gatsby';
import { useI18next } from '../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { container } from './thank-you.module.scss';

import { IPage } from '../../../models/page.model';

import HalloweenLayout from '../../../layouts/halloween-layout';
import HalloweenPrize from '../../../components/organisms/halloween-prize';

interface IHalloweenThankYouPageProps {
    readonly data: {
        page: IPage | null;
    };
}
const ThankYou: React.FC<IHalloweenThankYouPageProps> = ({ data }) => {
    const { t } = useI18next();
    const { page } = data;
    const from =
        typeof window !== 'undefined'
            ? new URLSearchParams(window.location.search).get('from')
            : '';

    return (
        <HalloweenLayout
            page={page}
            className={container}
            headerProps={
                from === 'winner'
                    ? {
                          titleSecondLine: t('halloween.thank.title.winner'),
                          subtitle: t('halloween.thank.subtitle.winner'),
                      }
                    : {
                          titleFirstLine: t('halloween.thank.title.1'),
                          titleSecondLine: t('halloween.thank.title.2'),
                      }
            }
            pageType={'thank-you'}
        >
            <HalloweenPrize />
        </HalloweenLayout>
    );
};

export const query = graphql`
    query ($language: String!, $site: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        page: strapiPage(
            locale: { eq: $language }
            site: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            ...pageFields
        }
    }
`;

export default ThankYou;
